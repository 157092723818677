.container {
    // padding: 1px 16px;
    padding: 0;
    margin: 0;
    text-align: center;
}

.container-flex {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap as needed */
    justify-content: space-between;
}

.home-title {
    margin-bottom: 0em; /* Reduced bottom margin */
    margin-top: 0.5em; /* Increased top margin */
    font-size: 8vh; /* Large size for the title */
}

.home-subtitle {
    font-size: 1.5em; /* Smaller size for the date */
    color: #666; /* Optional: color for the date */
    margin-top: 0em; /* Reduced top margin */
    margin-bottom: 1vh;
}

.home-content {
    margin-left: calc((100vw - 90vw) / 2); /* Adjust for the sidebar's width */
    margin-right: calc((100vw - 90vw) / 2);
    text-align: left; /* Keep the text left-aligned */
    /* Other styles if needed */
}

@media (min-width: 1024px) { /* Assuming sidebar is visible from this breakpoint */
    .container {
        margin-left: 150px; /* Adjust based on sidebar width */
    }

    .code-block, .text-block {
        max-width: 45%;
        margin-right: 10px; /* Add some space between blocks */
    }
}


/* Responsive design for mobile */
@media (max-width: 1024px) {
    .home-content {
        max-width: 90%;
        padding: 10px;
        margin-left: 5%;
        margin-right: 5%;
    }
}

.code-block {
    background-color: #15151f;
    border: 1px solid #060606;
    border-left: 3px solid #aaaaff;
    font-family: monospace;
    line-height: 1.3;
    max-width: 45%; /* Adjust as needed */
    word-wrap: break-word;
    font-size: 1.9vh;
}

.text-block {
    /* font-size: 1.4vw; */
    margin-left: 0;
    max-width: 45%; /* Adjust as needed */
    font-size: 2.5vh;
}

@media screen and (max-width: 1024px) { /* Adjust the breakpoint as needed */
    .text-block {
        flex-basis: 100%;
        max-width: 100%;
        font-size: 2.5vh; /* Adjust font size for smaller screens */
    }
    .code-block {
        display: none;
    }
}

